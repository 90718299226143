'use client';
import { enqueueSnackbar } from 'notistack';
import { SWRConfig } from 'swr';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';

type CustomSwrConfig = Readonly<
  PublicConfiguration<any, any, BareFetcher<any>>
> & {
  errorMessage?: string;
};

export const SWRConfigProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <SWRConfig
      value={{
        onError: (error, _, config: CustomSwrConfig) => {
          console.error(error);

          const message = config.errorMessage
            ? config.errorMessage
            : 'An error has occured. Please try again later or contact support if the problem persists.';

          enqueueSnackbar(message, {
            variant: 'error',
          });
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
