'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { Userpilot } from 'userpilot';

export const UserpilotInitClient = ({
  user: { name, email, id },
}: {
  user: {
    id: string;
    name: string;
    email: string;
  };
}) => {
  const pathname = usePathname();

  useEffect(() => {
    Userpilot.initialize('NX-6e81effc');
  }, []);

  useEffect(() => {
    Userpilot.reload();
  }, [pathname]);

  useEffect(() => {
    if (name && email && id) {
      Userpilot.identify(id, {
        name,
        email,
      });
    }
  }, [name, email, id]);

  return null;
};
