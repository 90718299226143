'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export const DatadogInitClient = ({
  env,
  version,
}: {
  env: string;
  version: string;
}) => {
  useEffect(() => {
    datadogRum.init({
      env,
      version,
      applicationId: '64e7b143-ca18-4af2-9281-32529097f59c',
      clientToken: 'pubc303e667c24456e0db8bb639377c3523',
      site: 'datadoghq.com',
      service: 'cv-dashboard',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }, [env, version]);

  return null;
};
